/* eslint-disable no-restricted-globals */
import {precacheAndRoute} from "workbox-precaching";

precacheAndRoute(self.__WB_MANIFEST);

const cacheName = 'files';

self.addEventListener('fetch', fetchEvent => {
    const request = fetchEvent.request;
    if (request.method !== 'GET') {
        return;
    }
    fetchEvent.respondWith(async function () {
        const responseFromFetch = fetch(request);
        fetchEvent.waitUntil(async function () {
            const responseCopy = (await responseFromFetch).clone();
            const myCache = await caches.open(cacheName);
            await myCache.put(request, responseCopy);
        }());
        if (request.headers.get('Accept').includes('text/html')) {
            try {
                return await responseFromFetch;
            } catch (error) {
                return caches.match(request);
            }
        } else {
            const responseFromCache = await caches.match(request);
            return responseFromCache || responseFromFetch;
        }
    }());
});

// clean up!
self.addEventListener('activate', function (event) {
    event.waitUntil(
        caches.keys().then(function (cacheNames) {
            return Promise.all(
                cacheNames.filter(function (cacheName) {
                    // Return true if you want to remove this cache,
                    // but remember that caches are shared across
                    // the whole origin
                }).map(function (cacheName) {
                    return caches.delete(cacheName);
                })
            );
        })
    );
});

caches.keys().then(function(names) {
    for (let name of names)
        caches.delete(name);
});